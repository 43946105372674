<template>
  <v-container :fluid="true">
    <v-toolbar flat>
      <v-toolbar-title class="text-h5">
        Invoice OCR Data # {{ invoiceId }}
      </v-toolbar-title>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      item-key="upc"
      class="elevation-1"
      :loading="loading"
      striped
      height="69vh"
      fixed-header
      :footer-props="footerProps"
      theme="dark"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-checkbox class="mt-5" @change="handleSelectAll" dense></v-checkbox>
          <v-btn @click="showAddInvoiceDetailDialog = true" class="button">
            <v-icon left>mdi-plus</v-icon>
            Add Item
          </v-btn>
          <v-btn @click="downloadCSV" class="button">
            <v-icon left>mdi-download</v-icon>
            Download CSV
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="w40"
            outlined
            dense
          ></v-text-field>
        </v-toolbar>

        <!-- Add this new bulk edit form -->
        <v-expand-transition>
          <v-card
            v-if="selected.length > 0"
            class="bulk-edit-form ma-4 elevation-2"
          >
            <v-card-title class="text-subtitle-1 py-2">
              Bulk Edit {{ selected.length }} Selected Items
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="bulkEdit.description"
                    label="Description"
                    dense
                    outlined
                    hide-details
                    background-color="white"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="bulkEdit.cost_per_pack"
                    label="New Cost"
                    dense
                    outlined
                    hide-details
                    type="number"
                    background-color="white"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="bulkEdit.case_price"
                    label="Case Price"
                    dense
                    outlined
                    hide-details
                    type="number"
                    background-color="white"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="bulkEdit.extended_case_price"
                    label="Extended Case Price"
                    dense
                    outlined
                    hide-details
                    type="number"
                    background-color="white"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="bulkEdit.size"
                    label="Size"
                    dense
                    outlined
                    hide-details
                    background-color="white"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="bulkEdit.pack"
                    label="Pack"
                    dense
                    outlined
                    hide-details
                    type="number"
                    background-color="white"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="py-2">
              <v-spacer></v-spacer>
              <v-btn color="grey" text @click="handleBulkEditCancel"
                >Cancel</v-btn
              >
              <v-btn color="primary" @click="previewChanges" class="ml-4"
                >Preview Changes</v-btn
              >
            </v-card-actions>

            <!-- Add this preview section -->
            <v-dialog v-model="showPreview" max-width="1200px" persistent>
              <v-card>
                <v-card-title class="text-h6">
                  Preview Changes
                  <v-spacer></v-spacer>
                  <div class="text-subtitle-2">
                    {{ selected.length }} items will be updated
                  </div>
                </v-card-title>

                <v-card-text>
                  <v-data-table
                    :headers="previewHeaders"
                    :items="previewItems"
                    dense
                    class="elevation-1"
                  >
                    <template v-slot:item="{ item }">
                      <tr>
                        <td>{{ item.upc }}</td>
                        <td>
                          <div class="d-flex align-center">
                            <span class="old-value">{{
                              item.old.description || "-"
                            }}</span>
                            <v-icon small class="mx-2">mdi-arrow-right</v-icon>
                            <span class="new-value">{{
                              item.new.description || "-"
                            }}</span>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex align-center">
                            <span class="old-value">{{
                              item.old.cost_per_pack || "-"
                            }}</span>
                            <v-icon small class="mx-2">mdi-arrow-right</v-icon>
                            <span class="new-value">{{
                              item.new.cost_per_pack || "-"
                            }}</span>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex align-center">
                            <span class="old-value">{{
                              item.old.case_price || "-"
                            }}</span>
                            <v-icon small class="mx-2">mdi-arrow-right</v-icon>
                            <span class="new-value">{{
                              item.new.case_price || "-"
                            }}</span>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex align-center">
                            <span class="old-value">{{
                              item.old.extended_case_price || "-"
                            }}</span>
                            <v-icon small class="mx-2">mdi-arrow-right</v-icon>
                            <span class="new-value">{{
                              item.new.extended_case_price || "-"
                            }}</span>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex align-center">
                            <span class="old-value">{{
                              item.old.size || "-"
                            }}</span>
                            <v-icon small class="mx-2">mdi-arrow-right</v-icon>
                            <span class="new-value">{{
                              item.new.size || "-"
                            }}</span>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex align-center">
                            <span class="old-value">{{
                              item.old.pack || "-"
                            }}</span>
                            <v-icon small class="mx-2">mdi-arrow-right</v-icon>
                            <span class="new-value">{{
                              item.new.pack || "-"
                            }}</span>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="grey" text @click="showPreview = false"
                    >Cancel</v-btn
                  >
                  <v-btn color="primary" @click="applyBulkEdit"
                    >Apply Changes</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card>
        </v-expand-transition>
      </template>

      <!-- Custom Body Slot for Dynamic Row Classes -->
      <template v-slot:body="{ items }">
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="item.id"
            :class="getRowClass(item)"
          >
            <td>
              <v-checkbox
                v-model="selected"
                :value="item.id"
                @change="handleItemChek"
                dense
              >
              </v-checkbox>
            </td>
            <td>{{ index + 1 }}</td>
            <td>
              <span
                v-if="!isEditing(item, 'upc')"
                @dblclick="editField(item, 'upc')"
              >
                {{ item.upc ? item.upc : "-" }}
              </span>
              <v-text-field
                v-else
                v-model="item.upc"
                @blur="saveField(item, 'upc')"
                @keyup.enter="saveField(item, 'upc')"
                dense
              ></v-text-field>
            </td>

            <td>
              <span
                v-if="!isEditing(item, 'description')"
                @dblclick="editField(item, 'description')"
              >
                {{ item.description ? item.description : "-" }}
              </span>
              <v-text-field
                v-else
                v-model="item.description"
                @blur="saveField(item, 'description')"
                @keyup.enter="saveField(item, 'description')"
                dense
              ></v-text-field>
            </td>
            <td>
              <span>
                {{
                  item.inventory_item?.base_unit
                    ? item.inventory_item?.base_unit
                    : "-"
                }}
              </span>
            </td>
            <td>
              <span
                v-if="!isEditing(item, 'cost_per_pack')"
                @dblclick="editField(item, 'cost_per_pack')"
              >
                {{ item.cost_per_pack ? item.cost_per_pack : "-" }}
              </span>
              <v-text-field
                v-else
                v-model="item.cost_per_pack"
                @blur="saveField(item, 'cost_per_pack')"
                @keyup.enter="saveField(item, 'cost_per_pack')"
                dense
              ></v-text-field>
            </td>
            <td>
              <span>
                {{
                  item.inventory_item?.effective_price
                    ? item.inventory_item?.effective_price
                    : "-"
                }}
                /
                {{ getQuantity(item) }}
              </span>
            </td>

            <td>
              <span>
                {{ getType(item) }}
              </span>
            </td>

            <td>
              <span
                v-if="!isEditing(item, 'size')"
                @dblclick="editField(item, 'size')"
              >
                {{ item.size ? item.size : "-" }}
              </span>
              <v-text-field
                v-else
                v-model="item.size"
                @blur="saveField(item, 'size')"
                @keyup.enter="saveField(item, 'size')"
                dense
              ></v-text-field>
            </td>
            <td>
              <span
                v-if="!isEditing(item, 'pack')"
                @dblclick="editField(item, 'pack')"
              >
                {{ item.pack ? item.pack : "-" }}
              </span>
              <v-text-field
                v-else
                v-model="item.pack"
                @blur="saveField(item, 'pack')"
                @keyup.enter="saveField(item, 'pack')"
                dense
              ></v-text-field>
            </td>
            <td>
              <span
                v-if="!isEditing(item, 'case_price')"
                @dblclick="editField(item, 'case_price')"
              >
                {{ item.case_price ? item.case_price : "-" }}
              </span>
              <v-text-field
                v-else
                v-model="item.case_price"
                @blur="saveField(item, 'case_price')"
                @keyup.enter="saveField(item, 'case_price')"
                dense
              ></v-text-field>
            </td>

            <td>
              <span
                v-if="!isEditing(item, 'extended_case_price')"
                @dblclick="editField(item, 'extended_case_price')"
              >
                {{ item.extended_case_price ? item.extended_case_price : "-" }}
              </span>
              <v-text-field
                v-else
                v-model="item.extended_case_price"
                @blur="saveField(item, 'extended_case_price')"
                @keyup.enter="saveField(item, 'extended_case_price')"
                dense
              ></v-text-field>
            </td>
            <td>
              <v-chip
                v-if="item.status == 'old'"
                color="secondary"
                variant="flat"
                class="pill"
              >
                {{ item.status }}
              </v-chip>
              <v-chip color="green" class="pill" variant="flat" v-else>
                {{ item.status }}
              </v-chip>
            </td>
            <td>
              <span>
                {{ formattedDate(item.created_at) }}
              </span>
            </td>

            <td>
              <span>
                <v-icon @click="viewInvoiceDetail(item)">mdi-eye</v-icon>
              </span>
              <span>
                <v-icon @click="editInvoiceDetail(item)">mdi-pencil</v-icon>
              </span>
              <span>
                <v-icon class="text-red" @click="deleteInvoiceDetail(item)"
                  >mdi-delete</v-icon
                >
              </span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <add-invoice-detail-dialog
      v-if="showAddInvoiceDetailDialog"
      v-model="showAddInvoiceDetailDialog"
      @close-dialog="showAddInvoiceDetailDialog = false"
      @reload-invoice-details="getInvoiceDetail"
      @show-alert="showAlertData"
      :invoice_id="invoiceId"
    />

    <delete-invoice-detail-dialog
      v-if="showDeleteInvoiceDetailDialog"
      v-model="showDeleteInvoiceDetailDialog"
      :detailItem="selectedDetail"
      @close-dialog="showDeleteInvoiceDetailDialog = false"
      @reload-invoice-details="getInvoiceDetail"
      @show-alert="showAlertData"
    />

    <view-invoice-detail-dialog
      v-if="showviewInvoiceDetailDialog"
      v-model="showviewInvoiceDetailDialog"
      :invoiceDetail="selectedDetail"
      @close-dialog="showviewInvoiceDetailDialog = false"
    />

    <edit-invoice-detail-dialog
      v-if="showEditInvoiceDetailDialog"
      v-model="showEditInvoiceDetailDialog"
      :invoiceDetail="selectedDetail"
      @close-dialog="showEditInvoiceDetailDialog = false"
      @reload-invoice-details="getInvoiceDetail"
      @show-alert="showAlertData"
    />

    <alert-component
      v-if="showAlert"
      v-model="showAlert"
      :data="alertData"
      @close-alert="showAlert = false"
    />
  </v-container>
</template>

<script>
import AddInvoiceDetailDialog from "@/components/Invoices/InvoiceDetail/AddInvoiceDetailDialog";
import DeleteInvoiceDetailDialog from "@/components/Invoices/InvoiceDetail/DeleteInvoiceDetailDialog";
import ViewInvoiceDetailDialog from "@/components/Invoices/InvoiceDetail/ViewInvoiceDetailDialog";
import EditInvoiceDetailDialog from "@/components/Invoices/InvoiceDetail/EditInvoiceDetailDialog";
import AlertComponent from "@/components/common/AlertComponent";

export default {
  name: "invoice-detail-listing",
  data() {
    return {
      search: "",
      selected: [],
      loading: false,
      itemsPerPage: 10,
      counter: 0,
      showAlert: false,
      alertData: {},
      bulkEdit: {
        upc: "",
        description: "",
        cost_per_pack: "",
        case_price: "",
        extended_case_price: "",
      },
      showPreview: false,
      previewItems: [],
      previewHeaders: [
        { text: "UPC", value: "upc" },
        { text: "Description", value: "description" },
        { text: "Cost Per Pack", value: "cost_per_pack" },
        { text: "Case Price", value: "case_price" },
        { text: "Extended Case Price", value: "extended_case_price" },
        { text: "Size", value: "size" },
        { text: "Pack", value: "pack" },
      ],
      headers: [
        {
          text: "",
          value: "",
          sortable: false,
          class: "table-header",
        },
        {
          text: "Sr.#",
          value: "number",
          sortable: false,
          class: "table-header",
        },
        {
          text: "UPC",
          value: "upc",
          class: "table-header",
        },
        {
          text: "Description",
          value: "description",
          class: "table-header",
          width: "15%",
        },
        {
          text: "Old Cost",
          value: "inventory_item",
          class: "table-header",
          width: "9%",
        },
        {
          text: "New Cost",
          value: "cost_per_pack",
          class: "table-header",
        },
        {
          text: "Effective Price / Qty",
          value: "inventory_item",
          class: "table-header",
          width: "9%",
        },
        {
          text: "Type",
          value: "type",
          class: "table-header",
          width: "5%",
        },
        {
          text: "Size",
          value: "size",
          class: "table-header",
          width: "5%",
        },
        {
          text: "Pack",
          value: "pack",
          class: "table-header",
        },
        {
          text: "Case Price",
          value: "case_price",
          class: "table-header",
        },
        {
          text: "Extended Case Price",
          value: "extended_case_price",
          class: "table-header",
        },
        {
          text: "Status",
          value: "status",
          class: "table-header",
          width: "9%",
        },
        {
          text: "Added At",
          value: "created_at",
          class: "table-header",
          width: "8%",
        },
        {
          text: "Actions",
          value: "action",
          class: "table-header",
          width: "8%",
        },
      ],
      footerProps: {
        "items-per-page-options": [
          20,
          50,
          100,
          500,
          { text: "All", value: -1 },
        ],
      },
      items: [],
      showAddInvoiceDetailDialog: false,
      showDeleteInvoiceDetailDialog: false,
      showviewInvoiceDetailDialog: false,
      showEditInvoiceDetailDialog: false,
      selectedDetail: {},
      selectedVendor: null,
      invoiceId: null,
      editingItem: null,
      editingField: "",
      datePickerVisible: false,
    };
  },
  components: {
    AddInvoiceDetailDialog,
    AlertComponent,
    DeleteInvoiceDetailDialog,
    ViewInvoiceDetailDialog,
    EditInvoiceDetailDialog,
  },
  mounted() {
    document.title = "Razcofoods | Invoice Detail";
  },
  created() {
    this.invoiceId = this.$route.params.invoice_id;
    this.getInvoiceDetail();
  },
  methods: {
    async getInvoiceDetail() {
      this.items = [];
      this.loading = true;
      await this.$axios
        .get(process.env.VUE_APP_API_URL + "/invoice/data/" + this.invoiceId)
        .then((res) => {
          if (res.data && res.data.status) {
            this.items = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });

      this.loading = false;
    },
    handleBulkEditCancel() {
      this.bulkEdit = {
        upc: "",
        description: "",
        cost_per_pack: "",
        case_price: "",
        extended_case_price: "",
      };

      this.selected = [];
    },
    async applyBulkEdit() {
      const updates = {};

      // Only include fields that have values
      if (this.bulkEdit.description)
        updates.description = this.bulkEdit.description;
      if (this.bulkEdit.cost_per_pack)
        updates.cost_per_pack = this.bulkEdit.cost_per_pack;
      if (this.bulkEdit.case_price)
        updates.case_price = this.bulkEdit.case_price;
      if (this.bulkEdit.extended_case_price)
        updates.extended_case_price = this.bulkEdit.extended_case_price;
      if (this.bulkEdit.size) updates.size = this.bulkEdit.size;
      if (this.bulkEdit.pack) updates.pack = this.bulkEdit.pack;

      // Update each selected item
      for (const id of this.selected) {
        const item = this.items.find((i) => i.id === id);
        if (item) {
          Object.assign(item, updates);
          await this.updateInvoiceDetailBulk(item);
        }

        this.showPreview = false;
        this.handleBulkEditCancel();
      }

      // Reset form
      this.bulkEdit = {
        upc: "",
        description: "",
        cost_per_pack: "",
        case_price: "",
        extended_case_price: "",
      };
    },
    handleItemChek() {
      console.log(this.selected);
    },
    previewChanges() {
      this.previewItems = this.selected.map((id) => {
        const item = this.items.find((i) => i.id === id);
        return {
          upc: item.upc,
          old: {
            cost_per_pack: item.cost_per_pack,
            case_price: item.case_price,
            extended_case_price: item.extended_case_price,
            size: item.size,
            pack: item.pack,
            description: item.description,
          },
          new: {
            cost_per_pack: this.bulkEdit.cost_per_pack || item.cost_per_pack,
            case_price: this.bulkEdit.case_price || item.case_price,
            extended_case_price: this.bulkEdit.extended_case_price || item.extended_case_price,
            size: this.bulkEdit.size || item.size,
            pack: this.bulkEdit.pack || item.pack,
            description: this.bulkEdit.description || item.description,
          },
        };
      });
      this.showPreview = true;
    },
    formattedDate(date) {
      let dateToFormat = new Date(date);
      return dateToFormat.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
    getRowClass(item) {
      const costPerPack = parseFloat(item.cost_per_pack) || 0;
      const baseUnit = parseFloat(item.inventory_item?.base_unit) || 0;

      if (costPerPack > 0 && baseUnit > 0 && costPerPack > baseUnit) {
        return "low";
      } else if (costPerPack > 0 && baseUnit > 0 && costPerPack < baseUnit) {
        return "high";
      }
      return "";
    },
    getType(item) {
      const tpr = parseFloat(item.inventory_item?.tpr) || 0;
      const adp = parseFloat(item.inventory_item?.adp) || 0;
      const retail_price = parseFloat(item.inventory_item?.retail) || 0;
      const effective_price =
        parseFloat(item.inventory_item?.effective_price) || 0;

      if (item.status == "new") {
        return "-";
      }

      if (tpr == effective_price) {
        return "TPR";
      } else if (adp == effective_price) {
        return "ADP";
      } else if (retail_price == effective_price) {
        return "REG";
      } else {
        return "-";
      }
    },
    handleSelectAll(event) {
      if (event) {
        this.selected = this.items.map((item) => item.id);
      } else {
        this.selected = [];
      }

      console.log(this.selected);
    },
    getQuantity(item) {
      const type = this.getType(item);

      if (type == "TPR") {
        return item.inventory_item.tpr_qty;
      } else if (type == "ADP") {
        return item.inventory_item.adp_qty;
      } else {
        return "-";
      }
    },
    showAlertData(alertData) {
      this.alertData = alertData;
      this.showAlert = true;
    },
    viewInvoiceDetail(item) {
      this.selectedDetail = item;
      this.showviewInvoiceDetailDialog = true;
    },
    editInvoiceDetail(item) {
      this.selectedDetail = item;
      this.showEditInvoiceDetailDialog = true;
    },
    deleteInvoiceDetail(item) {
      this.selectedDetail = item;
      this.showDeleteInvoiceDetailDialog = true;
    },
    isEditing(item, field) {
      return this.editingItem === item && this.editingField === field;
    },
    editField(item, field) {
      this.editingItem = item;
      this.editingField = field;
    },
    saveField(item, field) {
      console.log(field);
      this.updateInvoiceDetail(item);

      // Clear editing state
      this.editingItem = null;
      this.editingField = "";
    },
    async updateInvoiceDetail(item) {
      let res = await this.$axios.put(
        process.env.VUE_APP_API_URL + "/invoice/data/" + item.id,
        item
      );

      if (res.data.status) {
        this.alertData.alertText = res.data.message;
        this.alertData.alertType = "success";
      } else {
        this.alertData.alertText = res.data.message;
        this.alertData.alertType = "error";
      }

      this.showAlert = true;
    },
    async updateInvoiceDetailBulk(item) {
      let res = await this.$axios.put(
        process.env.VUE_APP_API_URL + "/invoice/data/bulk/" + item.id,
        item
      );

      if (res.data.status) {
        this.alertData.alertText = res.data.message;
        this.alertData.alertType = "success";
      } else {
        this.alertData.alertText = res.data.message;
        this.alertData.alertType = "error";
      }

      this.showAlert = true;
    },
    closeDatePicker() {
      this.datePickerVisible = false;
    },
    downloadCSV() {
      const csvContent = this.generateCSV();
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "invoice_data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    generateCSV() {
      const header = this.headers
        .filter((h) => h.value !== "action")
        .map((h) => h.text)
        .join(",");

      const rows = this.items.map((item) =>
        this.headers
          .filter((h) => h.value !== "action")
          .map((h) => this.formatCSVField(item[h.value]))
          .join(",")
      );

      return [header, ...rows].join("\n");
    },
    formatCSVField(value) {
      if (value === null || value === undefined) {
        return "";
      }
      const escapedValue = String(value).replace(/"/g, '""');
      return `"${escapedValue}"`;
    },
  },
};
</script>

<style scoped>
.v-data-table-header {
  font-weight: bold;
}

.w40 {
  max-width: 220px;
}

.text-align-center {
  text-align: center !important;
}

.margin-left-3 {
  margin-left: -3px;
}

.padding-top-20 {
  padding-top: 20px;
}

.table-header {
  background: #8b1a29 !important;
  color: #fff !important;
  font-size: 14px !important;
  font-weight: bold !important;
  text-align: center !important;
}

.theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
  color: #fff !important;
}
.v-application .primary--text {
  color: #8b1a29 !important;
}
.v-application--is-ltr .v-text-field .v-label {
  color: #8b1a29 !important;
}
.text-red {
  color: red;
}
.text-white {
  color: white;
}
.v-select {
  margin-top: 24px;
  width: 50%;
  max-width: 20%;
}
.button {
  margin-right: 20px;
  background-color: #8b1a29 !important;
  color: #fff;
  float: right;
}

@media (min-width: 1904px) {
  .container {
    max-width: 1888px;
  }
}

@media (min-width: 1264px) {
  /* .container {
    max-width: 1440px;
  } */
}

.container {
  background-color: #fdf5f5;
}

.pill {
  text-transform: capitalize;
  color: #fff;
  font-weight: 700;
}

.pill.green {
  background-color: #5ba167 !important;
  border-color: #5ba167 !important;
}

.low {
  background-color: #95081b;
  color: white;
}

.high {
  background-color: #0b7828;
  color: white;
}

.low:hover {
  background-color: #bb0d24 !important;
}

.high:hover {
  background-color: #298541 !important;
}

.bulk-edit-form {
  background-color: #fafafa !important;
  border-radius: 8px;
}

.bulk-edit-form .v-card__title {
  background-color: #f0f0f0;
  border-bottom: 1px solid #e0e0e0;
}

.bulk-edit-form .v-text-field--outlined {
  border-radius: 4px;
}

.bulk-edit-form .v-card__actions {
  background-color: #f5f5f5;
  border-top: 1px solid #e0e0e0;
}

.old-value {
  color: #666;
  text-decoration: line-through;
}
.new-value {
  color: #4caf50;
  font-weight: bold;
}
.compact-checkbox ::v-deep .v-input__slot {
  margin-bottom: 0;
  transform: scale(0.8);
}

.compact-checkbox ::v-deep .v-input__control {
  min-height: 0 !important;
}

.compact-checkbox ::v-deep .v-input {
  margin-top: 0;
  padding-top: 0;
}
</style>
