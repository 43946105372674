<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      item-key="unique_identifier"
      class="elevation-1"
      :loading="loading"
      striped
      height="78vh"
      fixed-header
      :footer-props="footerProps"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-btn
            color="#8b1a29"
            class="ml-2 text-white"
            @click="showAddItemExpirationDialog = true"
          >
            <v-icon left>mdi-plus</v-icon>
            Add Item
          </v-btn>

          <v-spacer></v-spacer>

          <!-- Dropdown for filtering by expiration -->
          <v-select
            v-model="selectedFilter"
            :items="filterOptions"
            @change="getItemExpiration"
            label="Filter by Expiration"
            dense
            outlined
            class="mr-3"
            hide-details
            style="max-width: 200px"
          ></v-select>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="w40"
            outlined
            dense
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:[`item.number`]="{ index }">
        <span>{{ index + 1 }}</span>
      </template>

      <template v-slot:[`item.description`]="{ item }">
        <span class="text-align-center">{{
          item.description ? item.description : "-"
        }}</span>
      </template>
      <template v-slot:[`item.aisle`]="{ item }">
        <span class="text-align-center">{{
          item.aisle ? item.aisle : "-"
        }}</span>
      </template>
      <template v-slot:[`item.department`]="{ item }">
        <span class="text-align-center">{{
          item.department?.name ? item.department?.name : "-"
        }}</span>
      </template>

      <template v-slot:[`item.category`]="{ item }">
        <span class="text-align-center">{{
          item.category?.name ? item.category?.name : "-"
        }}</span>
      </template>
      <template v-slot:[`item.expiry_date`]="{ item }">
        <span class="text-align-center">{{
          item.expiry_date ? formattedDate(item.expiry_date) : "-"
        }}</span>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        <span>{{
          item.created_at ? formattedDate(item.created_at) : "-"
        }}</span>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <span>
          <v-icon @click="viewItemDetail(item)">mdi-eye</v-icon>
        </span>
        <span class="ml-1"
          ><v-icon @click="editItem(item)">mdi-pencil</v-icon></span
        >
        <span
          ><v-icon class="text-red" @click="DeleteItem(item)"
            >mdi-delete</v-icon
          ></span
        >
      </template>
    </v-data-table>

    <add-item-expiration-dialog
      v-if="showAddItemExpirationDialog"
      @close-dialog="showAddItemExpirationDialog = false"
      @reload-inventory="getItemExpiration"
      @show-alert="showAlertData"
    />

    <view-item-dialog
      v-if="showViewItemDialog"
      v-model="showViewItemDialog"
      :item="selectedItem"
      @close-dialog="showViewItemDialog = false"
    />

    <edit-item-dialog
      v-if="showEditItemDialog"
      v-model="showEditItemDialog"
      :item="selectedItem"
      @close-dialog="showEditItemDialog = false"
      @reload-vendors="getItemExpiration"
      @show-alert="showAlertData"
    />

    <delete-item-dialog
      v-if="showDeleteItemDialog"
      v-model="showDeleteItemDialog"
      :item="selectedItem"
      @close-dialog="showDeleteItemDialog = false"
      @reload-vendors="getItemExpiration"
      @show-alert="showAlertData"
    />

    <alert-component
      v-if="showAlert"
      v-model="showAlert"
      :data="alertData"
      @close-alert="showAlert = false"
    />
  </v-container>
</template>

<script>
import AddItemExpirationDialog from "@/components/ItemExpiration/AddItemExpiration";
import ViewItemDialog from "@/components/ItemExpiration/ViewItemExpiration";
import EditItemDialog from "@/components/ItemExpiration/EditItemExpiration";
import DeleteItemDialog from "@/components/ItemExpiration/DeleteItemExpiration";
import AlertComponent from "@/components/common/AlertComponent";

export default {
  name: "vendor-listing",
  data() {
    return {
      search: "",
      loading: false,
      itemsPerPage: 10,
      counter: 0,
      showAlert: false,
      alertData: {},
      headers: [
        {
          text: "Sr.#",
          value: "number",
          sortable: false,
          class: "table-header",
        },
        {
          text: "UPC",
          value: "upc",
          class: "table-header",
        },
        { text: "Description", value: "description", class: "table-header" },
        { text: "Aisle", value: "aisle", class: "table-header" },
        {
          text: "Department",
          value: "department",
          class: "table-header",
        },
        {
          text: "Category",
          value: "category",
          class: "table-header",
        },
        {
          text: "Expiry Date",
          value: "expiry_date",
          class: "table-header",
        },
        {
          text: "Added At",
          value: "created_at",
          class: "table-header",
        },
        {
          text: "Actions",
          value: "action",
          class: "table-header",
          width: "10%",
        },
      ],
      footerProps: {
        "items-per-page-options": [
          20,
          50,
          100,
          500,
          { text: "All", value: -1 },
        ],
      },
      items: [],
      showAddItemExpirationDialog: false,
      showViewItemDialog: false,
      showEditItemDialog: false,
      showDeleteItemDialog: false,
      selectedItem: {},
      filterOptions: [
        { text: "7 Days", value: 7 },
        { text: "15 Days", value: 15 },
        { text: "30 Days", value: 30 },
      ],
      selectedFilter: 15,
    };
  },
  components: {
    AddItemExpirationDialog,
    ViewItemDialog,
    EditItemDialog,
    AlertComponent,
    DeleteItemDialog,
  },
  mounted() {
    document.title = "Razcofoods | Items Expiration";
    this.getItemExpiration();
  },
  methods: {
    async getItemExpiration() {
      this.loading = true;
      let items = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/item-expiration" + '?filter_days=' + this.selectedFilter
      );
      if (items.data && items.data.status) {
        this.items = items.data.data;
      }
      this.loading = false;
    },
    formattedDate(date) {
      let dateToFormat = new Date(date);
      return dateToFormat.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    showAlertData(alertData) {
      this.alertData = alertData;
      this.showAlert = true;
    },
    viewItemDetail(item) {
      this.selectedItem = item;
      this.showViewItemDialog = true;
    },
    editItem(item) {
      this.selectedItem = item;
      this.showEditItemDialog = true;
    },
    DeleteItem(item) {
      this.selectedItem = item;
      this.showDeleteItemDialog = true;
    },
  },
};
</script>

<style scoped>
.v-data-table-header {
  font-weight: bold;
}

.w40 {
  max-width: 220px;
}

.text-align-center {
  text-align: center !important;
}

.margin-left-3 {
  margin-left: -3px;
}

.padding-top-20 {
  padding-top: 20px;
}

.table-header {
  background: #8b1a29 !important;
  color: #fff !important;
  font-size: 14px !important;
  font-weight: bold !important;
  text-align: center !important;
}

.theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
  color: #fff !important;
}

.v-application .primary--text {
  color: #8b1a29 !important;
}

.v-application--is-ltr .v-text-field .v-label {
  color: #8b1a29 !important;
}

.text-red {
  color: red;
}

.text-white {
  color: white;
}
</style>
