<template>
  <v-app>
    <v-navigation-drawer app permanent>
      <!-- Top section for App Name -->
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6 text-center">
              <img src="@/assets/razco-logo.webp" />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <!-- Center section for Navigation Links -->
      <v-divider></v-divider>
      <v-list>
        <v-list-item
          v-for="(page, index) in pages"
          :key="index"
          link
          :class="{ 'v-list-item--active': activeItem === page.value }"
          @click="handlePageClick(page)"
        >
          <v-list-item-icon>
            <v-icon>{{ page.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ page.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <!-- Spacer to push content to the bottom -->
      <v-spacer></v-spacer>

      <!-- Bottom section for Customer Image and Name -->
      <v-list>
        <v-list-item class="bottom-profile">
          <v-menu
            offset-y
            transition="slide-y-reverse-transition"
            close-on-content-click
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-avatar class="ml-2" v-bind="attrs" v-on="on">
                <v-img :src="customer.image"></v-img>
              </v-list-item-avatar>
            </template>
            <v-list>
              <v-list-item @click="logout">
                <v-list-item-content>
                  <v-list-item-title>Logout</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-list-item-content>
            <v-list-item-title>{{ customer.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main class="set-background">
      <!-- Dynamic component rendering -->
      <component :is="currentComponent"></component>
    </v-main>
  </v-app>
</template>

<script>
import ListingData from "@/components/Inventory/ListingData";
import HistoryListing from "@/components/History/HistoryListing";
import VendorListing from "@/components/Vendors/VendorListing";
import UserListing from "@/components/Users/UserListing";
import InvoiceListing from "@/components/Invoices/InvoiceListing";
import InvoiceDetailListing from "@/components/Invoices/InvoiceDetail/InvoiceDetailListing";
import InvoiceComparison from "@/components/Invoices/InvoiceComparison";
import PriceChangeListing from "@/components/PriceChange/PriceChangeListing";
import CategoryListing from "@/components/Category/CategoryListing";
import DepartmentListing from "@/components/Department/DepartmentListing";
import ItemExpirationListing from "../ItemExpiration/ItemExpirationListing";
import NewItemListing from "../NewItems/NewItemListing";
import router from "@/router";

export default {
  data() {
    return {
      activeItem: router.currentRoute.name,
      pages: [
        { name: "Items Management", icon: "mdi-bank", value: "items" },
        { name: "New Items", icon: "mdi-new-box", value: "new_items" },
        { name: "Items Expiration", icon: "mdi-clock-alert-outline", value: "item_expiration" },
        { name: "Histories", icon: "mdi-history", value: "histories" },
        { name: "Vendors", icon: "mdi-account-multiple", value: "vendors" },
        { name: "Invoices", icon: "mdi-note-text", value: "invoices" },
        { name: "Price Change", icon: "mdi-cash", value: "price_change" },
        { name: "Categories", icon: "mdi-view-grid", value: "categories" },
        { name: "Departments", icon: "mdi-office-building", value: "departments" },
        { name: "Users", icon: "mdi-account-outline", value: "users" }
      ],
      customer: {
        image: "https://randomuser.me/api/portraits/men/85.jpg",
        name: JSON.parse(localStorage.getItem('grocer_user'))?.name,
      },
    };
  },
  methods: {
    handlePageClick(page) {
      this.activeItem = page.value;
      this.$router.push("/" + page.value);
    },
    logout() {
      localStorage.removeItem('grocer_user');
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
  },
  computed: {
    currentComponent() {
      const componentsMap = {
        items: "ListingData",
        new_items: "NewItemListing",
        item_expiration: "ItemExpirationListing",
        histories: "HistoryListing",
        vendors: "VendorListing",
        invoices: "InvoiceListing",
        users: "UserListing",
        invoice_detail_listing: "InvoiceDetailListing",
        invoice_comparison: "InvoiceComparison",
        price_change: "PriceChangeListing",
        categories: "CategoryListing",
        departments: "DepartmentListing"
      };
      return componentsMap[this.activeItem];
    },
  },
  components: {
    ListingData,
    HistoryListing,
    VendorListing,
    UserListing,
    InvoiceListing,
    InvoiceDetailListing,
    InvoiceComparison,
    PriceChangeListing,
    CategoryListing,
    DepartmentListing,
    ItemExpirationListing,
    NewItemListing
  },
};
</script>

<style scoped>
.v-navigation-drawer {
  width: 250px;
  display: flex;
  flex-direction: column;
}

.v-list {
  margin: 0;
  padding: 0;
}

.bottom-profile {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 16px;
  padding-left: 8px;
}

.v-list-item--active {
  background-color: #8b1a29;
  color: #fff;
}
.set-background {
  background-color: #fdf5f5 !important;
}
</style>
