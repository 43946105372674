<template>
  <div>
    <v-dialog max-width="700" persistent v-model="dialog">
      <v-card>
        <v-card-title class="headline grey lighten-3">
          Add Item Expiration
        </v-card-title>

        <v-card-text class="mt-3">
          <v-form ref="form">
            <v-container>
              <v-row>
                <v-col cols="12" md="6" class="mb-3">
                  <multiselect
                    v-model="selectedUpc"
                    :options="upcList"
                    :custom-label="customLabel"
                    :loading="isLoading"
                    :searchable="true"
                    :internal-search="false"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :options-limit="10"
                    :limit="3"
                    :limit-text="(count) => `and ${count} more`"
                    @search-change="searchUpc"
                    placeholder="Search UPC"
                    label="upc"
                    track-by="upc"
                  >
                    <template slot="option" slot-scope="props">
                      <span
                        >{{ props.option.upc }} -
                        {{ props.option.description }}</span
                      >
                    </template>
                  </multiselect>
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="item.description"
                    label="Description"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-select
                    v-model="item.aisle"
                    :items="aisles"
                    item-text="name"
                    item-value="name"
                    label="Aisle"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-select
                    v-model="item.department_id"
                    :items="departments"
                    item-text="name"
                    item-value="id"
                    label="Department"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-select
                    v-model="item.category_id"
                    :items="categories"
                    item-text="name"
                    item-value="id"
                    label="Category"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="item.expiry_date"
                        label="Expiry Date"
                        readonly
                        outlined
                        dense
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="item.expiry_date"
                      @input="menu = false"
                      no-title
                      scrollable
                    />
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#8b1a29" @click="AddItem()" class="text-white"
            >Save</v-btn
          >
          <v-btn text @click="closeDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

export default {
  name: "add-item-expiration-dialog",

  data() {
    return {
      menu: false,
      item: {
        upc: "",
        description: "",
        aisle: "",
        department_id: "",
        category_id: "",
        expiry_date: "",
      },
      upcList: [],
      isLoading: false,
      selectedUpc: null,
      dialog: true,
      departments: [],
      categories: [],
      aisles: [
        { name: "1A" },
        { name: "1B" },
        { name: "2A" },
        { name: "2B" },
        { name: "3A" },
        { name: "3B" },
        { name: "4A" },
        { name: "4B" },
        { name: "5A" },
        { name: "5B" },
        { name: "6A" },
        { name: "6B" },
        { name: "7A" },
        { name: "7B" },
        { name: "8A" },
        { name: "8B" },
      ],
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    async AddItem() {
      let alertData = {};
      try {
        let res = await this.$axios.post(
          process.env.VUE_APP_API_URL + "/item-expiration",
          this.item
        );

        if (res.data.status) {
          alertData.alertText = res.data.message;
          alertData.alertType = "success";
          this.$emit("reload-inventory");
        } else {
          alertData.alertText = res.data.message;
          alertData.alertType = "error";
        }

        this.$emit("show-alert", alertData);
        this.$emit("close-dialog");
        this.dialog = false;
      } catch (error) {
        alertData.alertText = error.response.data.message;
        alertData.alertType = "error";
        this.$emit("show-alert", alertData);
        this.$emit("close-dialog");
        this.dialog = false;
      }
    },
    closeDialog() {
      this.$emit("close-dialog");
      this.dialog = false;
    },
    async getDepartments() {
      let res = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/department"
      );
      if (res.data.status) {
        this.departments = res.data.data;
      }
    },
    async getCategories() {
      let res = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/category"
      );
      if (res.data.status) {
        this.categories = res.data.data;
      }
    },
    customLabel({ upc }) {
      return `${upc}`;
    },
    async searchUpc(query) {
      if (!query) return;

      this.isLoading = true;
      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/get-items-by-upc?upc=${query}`
        );
        if (response.data.status) {
          this.upcList = response.data.data;
          console.log(this.upcList);
        }
      } catch (error) {
        console.error("Error fetching UPC list:", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.getDepartments();
    this.getCategories();
  },
  watch: {
    selectedUpc(val) {
      if (val) {
        this.item.upc = val.upc;
        this.item.description = val.description;
        this.item.department_id = val.department_id;
        this.item.category_id = val.category_id;
      }
    },
  },
};
</script>

<style lang="scss">
.multiselect {
  .multiselect__tags {
    background: white;
    border-radius: 4px;
    border: thin solid rgba(0, 0, 0, 0.38);
    min-height: 40px;
    padding: 4px 12px;
  }

  .multiselect__placeholder {
    color: #8b1a29;
    font-size: 14px;
    margin-bottom: 0;
    padding-top: 3px;
  }

  .multiselect__input {
    font-size: 14px;
  }

  .multiselect__single {
    font-size: 14px;
    padding-top: 2px;
  }

  .multiselect__content-wrapper {
    border-color: rgba(0, 0, 0, 0.12);
  }

  .multiselect__option {
    font-size: 14px;
    min-height: 40px;
    padding: 8px 12px;
  }
}

.v-application ul {
  padding-left: 2px !important;
}
</style>
