<template>
  <div>
    <v-dialog max-width="700" persistent v-model="dialog">
      <v-card>
        <v-card-title class="headline grey lighten-3">
          Edit Item Expiration
        </v-card-title>

        <v-card-text class="mt-3">
          <v-form ref="form">
            <v-container>
              <v-row>
                <v-col cols="12" md="6" class="mb-3">
                  <multiselect
                    v-model="selectedUpc"
                    :options="upcList"
                    :custom-label="customLabel"
                    :loading="isLoading"
                    :searchable="true"
                    :internal-search="false"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :options-limit="10"
                    :limit="3"
                    :limit-text="(count) => `and ${count} more`"
                    @search-change="searchUpc"
                    placeholder="Search UPC"
                    label="upc"
                    track-by="upc"
                  >
                    <template slot="option" slot-scope="props">
                      <span
                        >{{ props.option.upc }} -
                        {{ props.option.description }}</span
                      >
                    </template>
                  </multiselect>
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localItem.description"
                    label="Description"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-select
                    v-model="localItem.aisle"
                    :items="aisles"
                    item-text="name"
                    item-value="name"
                    label="Aisle"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-select
                    v-model="localItem.department_id"
                    :items="departments"
                    item-text="name"
                    item-value="id"
                    label="Department"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-select
                    v-model="localItem.category_id"
                    :items="categories"
                    item-text="name"
                    item-value="id"
                    label="Category"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="localItem.expiry_date"
                        label="Expiry Date"
                        readonly
                        outlined
                        dense
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="localItem.expiry_date"
                      @input="menu = false"
                      no-title
                      scrollable
                    />
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#8b1a29"
            @click="updateItem(localItem)"
            class="text-white"
            >Save</v-btn
          >
          <v-btn text @click="closeDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

export default {
  name: "edit-item-dialog",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      menu: false,
      dialog: true,
      departments: [],
      categories: [],
      aisles: [
        { name: "1A" },
        { name: "1B" },
        { name: "2A" },
        { name: "2B" },
        { name: "3A" },
        { name: "3B" },
        { name: "4A" },
        { name: "4B" },
        { name: "5A" },
        { name: "5B" },
        { name: "6A" },
        { name: "6B" },
        { name: "7A" },
        { name: "7B" },
        { name: "8A" },
        { name: "8B" },
      ],
      localItem: { ...this.item },
      upcList: [],
      isLoading: false,
      selectedUpc: null,
    };
  },
  methods: {
    async updateItem(item) {
      let alertData = {};
      let res = await this.$axios.put(
        process.env.VUE_APP_API_URL + "/item-expiration/" + item.id,
        item
      );

      if (res.data.status) {
        alertData.alertText = res.data.message;
        alertData.alertType = "success";
        this.$emit("reload-vendors");
      } else {
        alertData.alertText = res.data.message;
        alertData.alertType = "error";
      }

      this.$emit("show-alert", alertData);
      this.$emit("close-dialog");
    },
    customLabel({ upc }) {
      return `${upc}`;
    },
    async searchUpc(query) {
      if (!query) return;

      this.isLoading = true;
      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/get-items-by-upc?upc=${query}`
        );
        if (response.data.status) {
          this.upcList = response.data.data;
          console.log(this.upcList);
        }
      } catch (error) {
        console.error("Error fetching UPC list:", error);
      } finally {
        this.isLoading = false;
      }
    },
    closeDialog() {
      this.$emit("close-dialog");
      this.dialog = false;
    },
    async getDepartments() {
      let res = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/department"
      );
      if (res.data.status) {
        this.departments = res.data.data;
      }
    },
    async getCategories() {
      let res = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/category"
      );
      if (res.data.status) {
        this.categories = res.data.data;
      }
    },
  },
  mounted() {
    this.getDepartments();
    this.getCategories();

    if (this.localItem.upc) {
      this.selectedUpc = {
        upc: this.localItem.upc,
        description: this.localItem.description,
        department_id: this.localItem.department_id,
        category_id: this.localItem.category_id,
      };
    }
  },
  watch: {
    selectedUpc(val) {
      if (val) {
        this.localItem.upc = val.upc;
        this.localItem.description = val.description;
        this.localItem.department_id = val.department_id;
        this.localItem.category_id = val.category_id;
      }
    },
  },
};
</script>
