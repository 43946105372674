<template>
    <!-- Delete Dialog Component -->
    <v-dialog v-model="showDeleteItemDialog" max-width="500" persistent>
      <v-card>
        <v-card-title class="headline grey lighten-3">
          Delete Item Expiration
        </v-card-title>
  
        <v-card-text class="mt-3">
          Are you sure you want to delete Item {{ item.upc }} ? 
        </v-card-text>
  
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="DeleteItem(item)">Yes</v-btn>
          <v-btn text @click="$emit('close-dialog')">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    name: "delete-item-expiration",
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      item: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        showDeleteItemDialog: this.value,
      };
    },
    watch: {
      value(val) {
        this.showDeleteItemDialog = val;
      },
      showDeleteItemDialog(val) {
        this.$emit("input", val);
        if (!val) {
          this.$emit("close-dialog");
        }
      },
    },
    methods: {
      async DeleteItem(item) {
        let alertData = {};
        let res = await this.$axios.delete(
          process.env.VUE_APP_API_URL + "/item-expiration/" + item.id
        );
  
        if (res.data.status) {
          alertData.alertText = res.data.message;
          alertData.alertType = "success";
          this.$emit("reload-vendors");
        } else {
          this.alertText = res.data.message;
          this.alertType = "error";
        }
  
        this.$emit("show-alert", alertData);
        this.$emit("close-dialog");
      },
    },
  };
  </script>
  